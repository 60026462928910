
new WOW({ offset: 70 }).init();

function loadSliders() {
	$('.photo-gallery__images').slick({
		centerMode: true,
		centerPadding: '0',
		slidesToShow: 4,
		arrows: false,
		autoplay: true,
		autoplaySpeed: 5000,
		responsive: [
			{
				breakpoint: 768,
				settings: {
					arrows: false,
					centerMode: true,
					centerPadding: '40px',
					slidesToShow: 2
				}
			},
			{
				breakpoint: 480,
				settings: {
					arrows: false,
					centerMode: true,
					centerPadding: '40px',
					slidesToShow: 1
				}
			}
		]
	});
}

var sliderScript = document.getElementById("deferredSlickScript");

sliderScript.addEventListener('load', function () {
	loadSliders();
});

$(document).ready(function () {




	if ($("#HomepageMarker").length !== 0) {
		$(".catlinks__link").addClass("wow fadeIn");

		if (window.matchMedia('(min-width:768px)').matches) {
			$(".header__starburst").addClass("wow fadeIn2").attr("data-wow-duration", "6s");
			$(".header__logo img").addClass("wow flipInX").attr("data-wow-delay", "0.5s");
		} else {
			$(".header__logo img").css("visibility", "visible");
		}

	} else {
		$(".header__logo img").css("visibility", "visible");
	}


	// ADD SLIDEDOWN ANIMATION TO DROPDOWN //
	$('.toplinks').on('show.bs.dropdown', function (e) {
		$(this).find('.dropdown-menu').first().stop(true, true).slideDown();
		window.scrollBy(0, 1);
	});


	// ADD SLIDEUP ANIMATION TO DROPDOWN //
	$('.toplinks').on('hide.bs.dropdown', function (e) {
		$(this).find('.dropdown-menu').first().stop(true, true).slideUp();


	});

	$(".toplinks__dropdown-link").each(function (index, value) {
		$(this).attr("data-wow-delay", index / 10 + "s");
		$(this).attr("data-wow-offset", 0);
		$(this).addClass("wow fadeIn");
	});


	$("img.has-tooltip").tooltip({
		title: function () {
			return $(this).attr("alt");
		},
		placement: "auto"
	});

	$("a.has-tooltip:not(.header__logo)").tooltip({
		placement: "auto"
	});

	$(".header__logo").tooltip({
		placement: "bottom"
	});


	function fixHeights() {
		$('.RotatorTestimonialContent').sameHeight();
		$(".clients__carousel .carousel-inner").css("height", "auto");
		$(".clients__carousel .carousel-inner").sameHeight();
		$('.BookButton').width($('.CheckButton').width());
	}

	setTimeout(fixHeights, 300);

	$(window).on("resize", function () {
		setTimeout(fixHeights, 300);
	});

	$(".testimonials .carousel").attr("id", "testimonialCarousel");



});

